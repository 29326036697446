import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import {Page404} from '../feature-pages';

export default function Custom404() {
    return <Page404 />
}

export const getStaticProps = (async (context: any) => {
    const translations = await serverSideTranslations(
        context.locale ?? 'en',
        ['common', 'footer']
    )
    return {
        props: {
            ...translations,
            hideFooter: true,
        },
    }
})